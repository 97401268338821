export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"og:title","name":"og:title","property":"og:title","content":"Benchmark Terminal"},{"hid":"og:site_name","name":"og:site_name","property":"og:site_name","content":"Benchmark Terminal"},{"hid":"description","name":"description","content":"Unique & actionable insights on the Electric Vehicle industry"},{"hid":"og:description","name":"og:description","property":"og:description","content":"Unique & actionable insights on the Electric Vehicle industry"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.png"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap"},{"rel":"stylesheet","type":"text/css","href":"https://api.mapbox.com/mapbox-gl-js/v2.15.0/mapbox-gl.css"}],"style":[],"script":[{"hid":"hubspot","src":"https://js-eu1.hsforms.net/forms/embed/v2.js"}],"noscript":[],"title":"Benchmark Terminal"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false

export const purgeCachedData = true