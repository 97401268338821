/* eslint-disable */
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('vue:error', (error, instance, info) => {
    console.log('--- vue:error ---')
    console.error('Vue error:', error)
    console.error('Vue error info:', info)
    console.error('Vue error instance:', instance)
    console.error('--- vue:error end ---')
  })
})
