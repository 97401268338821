import type { ItemMenuType, MenuDataType } from '~/types/menu.type'
import { ROLES } from '~/constants/roles'
import type { Authorization } from '~/utils/core/autorization'

const getOverviewMenuItem = (type: string) => {
  return {
    overview: {
      name: 'Overview',
      alias: `${type}-overview`,
      path: `/${type}/overview/`,
      icon: 'eye',
    },
  }
}

const MenuData: MenuDataType = {
  home: {
    name: 'Home',
    alias: 'home',
    path: '/home/',
    icon: 'home',
    pathObject: 'home',
    roles: [ROLES.LITHIUM_READ],
    empty: true,
  },
  lithium: {
    name: 'Lithium',
    alias: 'lithium',
    icon: 'lithium',
    pathObject: 'lithium',
    roles: [ROLES.LITHIUM_READ],
    hasMultiSelect: false,
    empty: true,
    items: {
      ...getOverviewMenuItem('lithium'),
      Prices: {
        name: 'Prices & Indices',
        alias: 'lithium-prices',
        path: '/lithium/prices/prices/',
        icon: 'dollar',
      },
      Demand: {
        name: 'Demand',
        alias: 'lithium-demand',
        path: '/lithium/demand/',
        icon: 'demand',
        empty: true,
      },
      supply: {
        name: 'Supply',
        alias: 'lithium-supply',
        path: '/lithium/supply/mined/companies/overview/',
        icon: 'supply',
        empty: true,
      },
      costs: {
        name: 'Costs',
        alias: 'lithium-costs',
        path: '/lithium/costs/lithium-carbonate/c1/',
        icon: 'costs',
        empty: true,
      },
      equityData: {
        name: 'Equity Data',
        alias: 'lithium-equity-data',
        path: '/lithium/equity-data/stock-market/',
        icon: 'database',
      },
    },
  },
}

const PRICES_LABEL = 'Prices'
const INDEX_LABEL = 'Index'

const AdminMenuData: MenuDataType = {
  batteryCells: {
    name: 'Battery Cells',
    alias: 'battery-cells',
    path: 'battery-cells',
    icon: 'battery-cells',
    empty: true,
    hasMultiSelect: false,
    roles: [ROLES.BATTERY_CELLS_ADMIN],
    withOutCurrentStyle: false,
    items: {
      prices: {
        name: PRICES_LABEL,
        alias: 'admin-battery-cells-prices',
        path: '/admin/battery-cells/prices/',
        icon: 'dice-two',
        withOutCurrentStyle: true,
      },
      priceIndex: {
        name: 'Price Index',
        alias: 'admin-battery-cells-price-index',
        path: '/admin/battery-cells/price-index/',
        icon: 'dice-three',
        withOutCurrentStyle: true,
      },
      rawMaterialIndex: {
        name: 'Raw Material Index',
        alias: 'admin-battery-cells-raw-material-index',
        path: '/admin/battery-cells/raw-material-index/',
        icon: 'dice-four',
        withOutCurrentStyle: true,
      },
    },
  },
  cathode: {
    name: 'Cathodes',
    alias: 'cathodes',
    path: 'cathode',
    icon: 'plus',
    empty: true,
    hasMultiSelect: false,
    roles: [ROLES.CATHODES_ADMIN],
    withOutCurrentStyle: false,
    items: {
      prices: {
        name: PRICES_LABEL,
        alias: 'admin-cathodes-prices',
        path: '/admin/cathodes/prices/',
        icon: 'dice-two',
        withOutCurrentStyle: true,
      },
      priceIndex: {
        name: 'Price Index',
        alias: 'admin-cathodes-price-index',
        path: '/admin/cathodes/price-index/',
        icon: 'dice-four',
        withOutCurrentStyle: true,
      },
    },
  },
  anode: {
    name: 'Anodes',
    alias: 'anodes',
    path: 'anode',
    icon: 'minus',
    empty: true,
    hasMultiSelect: false,
    roles: [ROLES.ANODES_ADMIN],
    withOutCurrentStyle: false,
    items: {
      prices: {
        name: PRICES_LABEL,
        alias: 'admin-anodes-prices',
        path: '/admin/anodes/prices/',
        icon: 'dice-two',
        withOutCurrentStyle: true,
      },
      priceIndex: {
        name: 'Price Index',
        alias: 'admin-anodes-price-index',
        path: '/admin/anodes/price-index/',
        icon: 'dice-four',
        withOutCurrentStyle: true,
      },
    },
  },
  lithium: {
    name: 'Lithium',
    alias: 'lithium',
    path: 'lithium',
    icon: 'lithium',
    empty: true,
    hasMultiSelect: false,
    withOutCurrentStyle: false,
    roles: [ROLES.LITHIUM_ADMIN],
    items: {
      forecast: {
        name: 'Forecast',
        alias: 'admin-lithium-forecast',
        path: '/admin/lithium/forecast/',
        icon: 'dice-one',
        withOutCurrentStyle: true,
      },
      index: {
        name: 'Index',
        alias: 'admin-lithium-price-index',
        path: '/admin/lithium/price-index/',
        icon: 'dice-two',
        withOutCurrentStyle: true,
      },
      prices: {
        name: PRICES_LABEL,
        alias: 'admin-lithium-prices',
        path: '/admin/lithium/prices/',
        icon: 'dice-four',
        withOutCurrentStyle: true,
      },
      costs: {
        name: 'Costs',
        alias: 'admin-lithium-costs',
        path: '/admin/lithium/costs/',
        icon: 'dice-five',
        withOutCurrentStyle: true,
      },
      pricesForecast: {
        name: 'Prices Forecast',
        alias: 'admin-lithium-prices-forecast',
        path: '/admin/lithium/prices-forecast/',
        icon: 'dice-six',
        withOutCurrentStyle: true,
      },
      dailyPrices: {
        name: 'Daily Price',
        alias: 'admin-lithium-daily-price-indicator',
        path: '/admin/lithium/daily-price-indicator/',
        icon: 'dice-six',
        withOutCurrentStyle: true,
      },
    },
  },
  naturalGraphite: {
    name: 'Natural Graphite',
    alias: 'natural-graphite',
    path: 'natural-graphite',
    icon: 'natural-graphite',
    empty: true,
    hasMultiSelect: false,
    roles: [ROLES.NATURAL_GRAPHITE_ADMIN],
    withOutCurrentStyle: false,
    items: {
      prices: {
        name: PRICES_LABEL,
        alias: 'admin-natural-graphite-prices',
        path: '/admin/natural-graphite/prices/',
        icon: 'dice-one',
        withOutCurrentStyle: true,
      },
      priceIndex: {
        name: 'Price Index',
        alias: 'admin-natural-graphite-price-index',
        path: '/admin/natural-graphite/price-index/',
        icon: 'dice-two',
        withOutCurrentStyle: true,
      },
    },
  },
  nickel: {
    name: 'Nickel',
    alias: 'nickel',
    path: 'nickel',
    icon: 'nickel',
    empty: true,
    hasMultiSelect: false,
    roles: [ROLES.NICKEL_ADMIN],
    withOutCurrentStyle: false,
    items: {
      prices: {
        name: PRICES_LABEL,
        alias: 'admin-nickel-prices',
        path: '/admin/nickel/prices/',
        icon: 'dice-one',
        withOutCurrentStyle: true,
      },
      index: {
        name: INDEX_LABEL,
        alias: 'admin-nickel-price-index',
        path: '/admin/nickel/price-index/',
        icon: 'dice-one',
        withOutCurrentStyle: true,
      },
    },
  },
  syntheticGraphite: {
    name: 'Synthetic Graphite',
    alias: 'synthetic-graphite',
    path: 'synthetic-graphite',
    icon: 'natural-graphite',
    empty: true,
    hasMultiSelect: false,
    roles: [ROLES.SYNTHETIC_GRAPHITE_ADMIN],
    withOutCurrentStyle: false,
    items: {
      prices: {
        name: PRICES_LABEL,
        alias: 'admin-synthetic-graphite-prices',
        path: '/admin/synthetic-graphite/prices/',
        icon: 'dice-one',
        withOutCurrentStyle: true,
      },
      priceIndex: {
        name: 'Price Index',
        alias: 'synthetic-graphite-price-index',
        path: '/admin/synthetic-graphite/price-index/',
        icon: 'dice-two',
        withOutCurrentStyle: true,
      },
    },
  },
  cobalt: {
    name: 'Cobalt',
    alias: 'cobalt',
    path: 'cobalt',
    icon: 'cobalt',
    empty: true,
    hasMultiSelect: false,
    roles: [ROLES.COBALT_ADMIN],
    withOutCurrentStyle: false,
    items: {
      prices: {
        name: PRICES_LABEL,
        alias: 'admin-cobalt-prices',
        path: '/admin/cobalt/prices/',
        icon: 'dice-one',
        withOutCurrentStyle: true,
      },
      index: {
        name: INDEX_LABEL,
        alias: 'admin-cobalt-price-index',
        path: '/admin/cobalt/price-index/',
        icon: 'dice-one',
        withOutCurrentStyle: true,
      },
    },
  },
  rareEarths: {
    name: 'Rare Earths',
    alias: 'rare-earths',
    path: 'rare-earths',
    icon: 'motor',
    empty: true,
    hasMultiSelect: false,
    roles: [ROLES.RARE_EARTHS_ADMIN],
    withOutCurrentStyle: false,
    items: {
      prices: {
        name: PRICES_LABEL,
        alias: 'admin-rare-earths-prices',
        path: '/admin/rare-earths/prices/',
        icon: 'dice-one',
        withOutCurrentStyle: true,
      },
    },
  },
  blackMass: {
    name: 'Black Mass',
    alias: 'black-mass',
    icon: 'black-mass',
    roles: [ROLES.BLACK_MASS_ADMIN],
    hasMultiSelect: false,
    empty: true,
    items: {
      prices: {
        name: PRICES_LABEL,
        alias: 'admin-black-mass-prices',
        path: '/admin/black-mass/prices/',
        icon: 'dice-one',
        withOutCurrentStyle: true,
      },
      index: {
        name: 'Price Index',
        alias: 'admin-black-mass-price-index',
        path: '/admin/black-mass/price-index/',
        icon: 'dice-two',
        withOutCurrentStyle: true,
      },
    },
  },
  manganese: {
    name: 'Manganese',
    alias: 'manganese',
    icon: 'dice-one',
    hasMultiSelect: false,
    roles: [ROLES.MANGANESE_ADMIN, ROLES.MANGANESE_READ],
    empty: true,
    items: {
      prices: {
        name: PRICES_LABEL,
        alias: 'admin-manganese-prices',
        path: '/admin/manganese/prices/',
        icon: 'dice-one',
        withOutCurrentStyle: true,
      },
    },
  },
  electrolyte: {
    name: 'Electrolyte',
    alias: 'electrolyte',
    icon: 'dice-one',
    hasMultiSelect: false,
    roles: [ROLES.ELECTROLYTE_ADMIN],
    empty: true,
    items: {
      prices: {
        name: PRICES_LABEL,
        alias: 'admin-electrolyte-prices',
        path: '/admin/electrolyte/prices/',
        icon: 'dice-one',
        withOutCurrentStyle: true,
      },
    },
  },
  spotlight: {
    name: 'Price Spotlight',
    alias: 'admin-spotlight-index',
    path: '/admin/price-spotlight/',
    icon: 'dice-one',
    roles: [ROLES.SPOTLIGHT_ADMIN],
    withOutCurrentStyle: true,
    empty: true,
  },
  userManagement: {
    name: 'Terminal Users',
    alias: 'users',
    icon: 'dice-one',
    roles: [ROLES.USER_ADMIN],
    hasMultiSelect: false,
    empty: true,
    items: {
      management: {
        name: 'Management',
        alias: 'admin-users-management',
        path: '/admin/users/management/',
        icon: 'dice-one',
        withOutCurrentStyle: true,
      },
    },
  },
}

const filterMenuByRoles = (
  typeMenu: MenuDataType,
  authorizationInstance: Authorization,
): { [key: number]: ItemMenuType } => {
  const menuData = Object.values(typeMenu)
  const menuFilteredByRoles = menuData.filter((itemMenu) =>
    authorizationInstance.hasAccess(itemMenu.roles),
  )
  return { ...menuFilteredByRoles }
}

const getFirstUrlByRoles = (
  isAdminMode: boolean,
  authorizationInstance: Authorization,
  path: string,
) => {
  const typeMenu = isAdminMode ? AdminMenuData : MenuData
  let menu = filterMenuByRoles(typeMenu, authorizationInstance)
  const defaultPath = isAdminMode ? `${path.split('/').splice(0, 3).join('/')}/master/` : '/'
  let firstUrl: string

  if (Object.keys(menu).length > 0) {
    if (!isAdminMode) {
      firstUrl = menu[0]?.items ? menu[0].items.overview.path : menu[0].path
    } else {
      const keyFirstItem = Object.keys(menu[0].items)[0]
      firstUrl = `${menu[0].items[keyFirstItem].path}`
    }
  }

  if (isAdminMode && Object.keys(menu).length === 0) {
    menu = filterMenuByRoles(MenuData, authorizationInstance)
    firstUrl = `${menu[0]?.items?.overview?.path}`
  }

  if (!firstUrl && !isAdminMode && Object.keys(menu).length === 0) {
    const menuAdmin = filterMenuByRoles(AdminMenuData, authorizationInstance)
    const hasSomeRoles = Object.keys(menuAdmin).length > 0
    const menuItemsKeys = hasSomeRoles ? Object.keys(menuAdmin[0].items) : {}
    const url = hasSomeRoles ? `${menuAdmin[0]?.items?.[menuItemsKeys[0]]?.path}` : null
    if (url) {
      return url
    }
  }

  return Object.keys(menu).length !== 0 ? firstUrl : defaultPath
}

export { MenuData, AdminMenuData, getFirstUrlByRoles, filterMenuByRoles }
