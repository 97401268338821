
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as home1X1vbQjRGYUHsjkAYQeB77Ny_45mPZiekC3FzsrbMAB4sMeta } from "/opt/buildhome/repo/src/pages/home.vue?macro=true";
import { default as indexL6IBblXwOeGe20PFY6CEA_XHd4mNKrrU5AlHwcK4aA4Meta } from "/opt/buildhome/repo/src/pages/index.vue?macro=true";
import { default as loginbJvt5TGg7rlFXbQ_BRonmdD9Z27Cc7WHsQr4puFvsUYMeta } from "/opt/buildhome/repo/src/pages/login.vue?macro=true";
import { default as accountMYC2w7V1OPkvvLPK_45xz9N9sCHLs_eadyNkAwLpPMW7AMeta } from "/opt/buildhome/repo/src/pages/account.vue?macro=true";
import { default as indexb6lWDIOgpbk9hb7zBD4RytNqZfgABlB1U_40CnWtGMIMeta } from "/opt/buildhome/repo/src/pages/admin/index.vue?macro=true";
import { default as _91type_93ovP3jRbW1vHZUzuoCaV_p2_45A5S6fwgCsjPJQNXB6CA0Meta } from "/opt/buildhome/repo/src/pages/lithium/costs/[product]/[type].vue?macro=true";
import { default as costsktUvoRPgkMFJZI1nXmrHQHwfqdoMziDebNohhhvXoQUMeta } from "/opt/buildhome/repo/src/pages/lithium/costs.vue?macro=true";
import { default as demand73QAuqlwXt9yeb7UZO9wrxUHLZJJWWh4D3Y1tksiEg8Meta } from "/opt/buildhome/repo/src/pages/lithium/demand.vue?macro=true";
import { default as utils1OlQdgm1tRmSBRHkgGkd7HfIZKPXN1EoJ6oiF83VoQkMeta } from "/opt/buildhome/repo/src/pages/lithium/prices/utils.ts?macro=true";
import { default as indexSNKEjTKuQQuOAvCNROIwQUFHfMW2nqUphaapbknUvDUMeta } from "/opt/buildhome/repo/src/pages/lithium/prices/index.vue?macro=true";
import { default as rangevI3dIOEfh7LDnVE1bo7haJl0RQLkIqwaieMC7a9gU5gMeta } from "/opt/buildhome/repo/src/pages/lithium/prices/range.vue?macro=true";
import { default as tablevNK42_87V9KYq4Jr8em6HS8GDcdJFq4sgbggU_45ICZpAMeta } from "/opt/buildhome/repo/src/pages/lithium/prices/table.vue?macro=true";
import { default as priceseSJkDpp5WzVeyZglgDYf_tofFyp0PUwUhXg8_5OKoigMeta } from "/opt/buildhome/repo/src/pages/lithium/prices/prices.vue?macro=true";
import { default as forecastsWsRK1nqMrNbGpK94FDjdOZ3B02RKEQeBcCUg63WIkY0Meta } from "/opt/buildhome/repo/src/pages/lithium/prices/forecasts.vue?macro=true";
import { default as priceindex3vFq84LRXdYn5o5_4508Pl35O2_45nYFahySj7jWnwVRTgQMeta } from "/opt/buildhome/repo/src/pages/lithium/prices/priceindex.vue?macro=true";
import { default as prices1YcWEiJyF3TjLgXwyRzy2krgQymacxXNkj0vUklSQ1MMeta } from "/opt/buildhome/repo/src/pages/lithium/prices.vue?macro=true";
import { default as request_45accessD3YpmteoaZsbwC7fLRaLgD967Q2CuusJ7ge1hB1Xe4MMeta } from "/opt/buildhome/repo/src/pages/request-access.vue?macro=true";
import { default as _91tab_93RjVX0xR_eOEJ37IPnu6VDQ4s_cIltE_mWA_45_v_45drYdMMeta } from "/opt/buildhome/repo/src/pages/lithium/overview/[tab].vue?macro=true";
import { default as overviewaFdfAOcfAab4plxqexAzpHku214Z0EK65U5KVHOH_45DUMeta } from "/opt/buildhome/repo/src/pages/lithium/overview.vue?macro=true";
import { default as prices9n7v3p91VGFuG3pi7t10lhqNQvKFRQi46vtgxsuqGe4Meta } from "/opt/buildhome/repo/src/pages/admin/anodes/prices.vue?macro=true";
import { default as pricesR7S9npHINyI82hBwau4C28otH_f4I1DVOnfzuWcfNWIMeta } from "/opt/buildhome/repo/src/pages/admin/cobalt/prices.vue?macro=true";
import { default as costseCXxD8qG0FbFaRE9XFMKqIWKD_YPCLecObf6p3_45_xiIMeta } from "/opt/buildhome/repo/src/pages/admin/lithium/costs.vue?macro=true";
import { default as prices7vGpUOyyjNJm_Xg5jPbrrI7EQ11a_45GyJAdLJFPvLQ_450Meta } from "/opt/buildhome/repo/src/pages/admin/nickel/prices.vue?macro=true";
import { default as pricesz_eOE_45KFcYeA4_45UJa2AJ_s89cV53cGcnWpFLtXN2zHcMeta } from "/opt/buildhome/repo/src/pages/admin/lithium/prices.vue?macro=true";
import { default as _91id_93cEZzTyz6rDULrB_cfpRA5e51oLDnHTx1w0ctjMpNruMMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/mined/mines/[id].vue?macro=true";
import { default as _91id_93b_45aRpWfncFbgflF5Wln71TK06MUsg8clLpLgjLQhsr0Meta } from "/opt/buildhome/repo/src/pages/lithium/supply/mined/regions/[id].vue?macro=true";
import { default as _91id_93enpV9BuudpEoJs8E2knDMhnrtqcnV0Aq1LK_qF5mQxsMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/mined/ore-type/[id].vue?macro=true";
import { default as _91id_93KzOG_45jc8StRLjCP_0NRrNh_iSDwcpAhKRVYxNLeS6yQMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/mined/companies/[id].vue?macro=true";
import { default as _91id_93T4M_RoEiPDwhAOsgtsPBLa9wWR6vxr0KYxnZiSkv27EMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/mined/countries/[id].vue?macro=true";
import { default as overviewU_07ir8oLnnywXCI3aL0GEeHqq_xlytb98whbm8LYMUMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/mined/mines/overview.vue?macro=true";
import { default as overviewueHWZwzgeaYSd_noLA_wUK0UNj3oPTKNfn_45Jr55hkIUMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/mined/regions/overview.vue?macro=true";
import { default as overviewxrVeKBjd9HRG4LxeCPVxT0jFCGdr3ZgnyjfQ1hbfiH0Meta } from "/opt/buildhome/repo/src/pages/lithium/supply/mined/ore-type/overview.vue?macro=true";
import { default as overviewVaAiU_myu6Ea78ndBGswMZU5YyIb8R90zlXiEZLwaa4Meta } from "/opt/buildhome/repo/src/pages/lithium/supply/mined/companies/overview.vue?macro=true";
import { default as overviewev5AL11BaRfQy_2JGmz3GXoDtFiVceZ0cVO2CTAVLfgMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/mined/countries/overview.vue?macro=true";
import { default as minedtgGbBsZ7b9mjlXxePzRXhyCa7Z5nC4O0e_45ziRV9JmjwMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/mined.vue?macro=true";
import { default as prices5aiABjWP_45ifR2LGLvPWz5jttyfOSoXfU8RTi62gdMr8Meta } from "/opt/buildhome/repo/src/pages/admin/cathodes/prices.vue?macro=true";
import { default as ev_45sales_45kQnq8OhRL7qTg0hHgYFx7uh4GUcpWGu0z6ne7bh_OAMeta } from "/opt/buildhome/repo/src/pages/admin/lithium/ev-sales.vue?macro=true";
import { default as forecastyG4VY_w4PN6J4LbMiEAoljGS0yxTDbpuYGpXN9AeFRQMeta } from "/opt/buildhome/repo/src/pages/admin/lithium/forecast.vue?macro=true";
import { default as pricesQvqvg2EO4_59fR1XRfX9pIXtyfVtD2_45CKgdQl4FfUVUMeta } from "/opt/buildhome/repo/src/pages/admin/manganese/prices.vue?macro=true";
import { default as managementKPeqlo0qsGUaaPsptHs7IsGY4lwnweoRPyE3_4HNKQcMeta } from "/opt/buildhome/repo/src/pages/admin/users/management.vue?macro=true";
import { default as pricesxBcrreV5f4rdoqUXNlpFcpSJGZsQsvhkgWU8_45ssOjqcMeta } from "/opt/buildhome/repo/src/pages/admin/black-mass/prices.vue?macro=true";
import { default as _91id_93rdf_4591dlQoKpSrRuxRXOBEuxD3Dl6nTK4Xzvu_EIppwMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/plants/[id].vue?macro=true";
import { default as _91id_93F2j1KKtSXzdc_n8XuP_bRw_45oZuj39MZIbkJ31FUiu8IMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/regions/[id].vue?macro=true";
import { default as _91id_93_PsQaE_45q5uE69X2j01NyVvV3B7FICeJ8_45Yuzxk5qOCIMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/products/[id].vue?macro=true";
import { default as _91id_935bSM6HFF_45RZFunWslmXcsE7_jnf_45PKmNuFiL3e_I3xcMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/companies/[id].vue?macro=true";
import { default as _91id_93D7tCnk6eM44QF_45jw5hdkUcX5F9vPnQ_45UROllABMTY9gMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/countries/[id].vue?macro=true";
import { default as _91id_93fuZ5X8yyaWJbHrM4WarLtvFBFm4JdchdpyDFRH8ac5cMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/feedstocks/[id].vue?macro=true";
import { default as overviewjHQsK98PgH4nAACRSyklwo0_45bKOK3xhcqt7g44CQqLYMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/plants/overview.vue?macro=true";
import { default as overview64Z5zrqYu5oZUYyQjLOZUpFSXJCKDUijORXJafmKU5MMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/regions/overview.vue?macro=true";
import { default as overviewxgTWoVtRnFQ8iCqpEF0Fy_rHGDY59TQCfP7fhhJS1TwMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/products/overview.vue?macro=true";
import { default as overviewgQUvYooGRtn5kJyJtBP59I9EWvwW5ozEuOCzA_459t_4530Meta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/companies/overview.vue?macro=true";
import { default as overviewYM77iIzEsFNJCuA7E_gJywyOWHOLjHXzf0zqZFidyT4Meta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/countries/overview.vue?macro=true";
import { default as overviewaOKV_45EPrUKc6h1rxw261jeHqwhSCr69fJ5wSgMhh6gUMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical/feedstocks/overview.vue?macro=true";
import { default as chemicalOFg6oGCTcqiARJgaBfkwPIdzDkN4od0taTJMRbe_GGoMeta } from "/opt/buildhome/repo/src/pages/lithium/supply/chemical.vue?macro=true";
import { default as price_45indextqxM8yNsQsO1_8SkmhMDem9n7lCv_45UQOa7G_pAINNxUMeta } from "/opt/buildhome/repo/src/pages/admin/anodes/price-index.vue?macro=true";
import { default as price_45index6ULGMwZBci8L_16umAyIyYJnQl_YkvYMELJUuBGl8nMMeta } from "/opt/buildhome/repo/src/pages/admin/cobalt/price-index.vue?macro=true";
import { default as pricesvHaxsZD06e4Cl65dYfRcVZLVyh6ikbtu4f00Z53gZiMMeta } from "/opt/buildhome/repo/src/pages/admin/electrolyte/prices.vue?macro=true";
import { default as price_45indexEkzpV6md2eTz5RUR7xLZZ2fO6b7G4fi9bDqpSEDbWZcMeta } from "/opt/buildhome/repo/src/pages/admin/nickel/price-index.vue?macro=true";
import { default as pricesU95eCF5M85UO4xe6dqslPS8_nexhKWdBw9Cg6nOsEwEMeta } from "/opt/buildhome/repo/src/pages/admin/rare-earths/prices.vue?macro=true";
import { default as denormalized7rhKWcgB5Soyh54fe2HT_rXpaBN4Rw5wU_sxedhVlRMMeta } from "/opt/buildhome/repo/src/pages/admin/anodes/denormalized.vue?macro=true";
import { default as price_45indexhLqHjoo4wSGXOcNQ6fnUICbAXBspCjnceo6gV2YmK3QMeta } from "/opt/buildhome/repo/src/pages/admin/lithium/price-index.vue?macro=true";
import { default as pricesxcx7UNhVPGtzrKZNZRGSG9uuBdrOxp0njufCDmWHZVEMeta } from "/opt/buildhome/repo/src/pages/admin/battery-cells/prices.vue?macro=true";
import { default as price_45indexOozLjZVoesW_4VnT7Mu1vYJ6oEuU7BRgMvWlYzvSItQMeta } from "/opt/buildhome/repo/src/pages/admin/cathodes/price-index.vue?macro=true";
import { default as pricingB9BvkIuVdEb5dCDeQphqG6_Cnp1mElpEP8l9Kjw5ww8Meta } from "/opt/buildhome/repo/src/pages/admin/lithium/crud/pricing.vue?macro=true";
import { default as indexUrOJxJNMXYHUm1z3asS3dHR4567X555RIYnK4xJZAg8Meta } from "/opt/buildhome/repo/src/pages/admin/price-spotlight/index.vue?macro=true";
import { default as price_45indexkoTs_UfMHF0DyjAbN22C6D6v0fGogQ7mhAzg_45CuvCA0Meta } from "/opt/buildhome/repo/src/pages/admin/black-mass/price-index.vue?macro=true";
import { default as prices_45forecastiskPif7Qdbp5QIzhS_Ur_450agCddb5d09Eoee5rkaPjEMeta } from "/opt/buildhome/repo/src/pages/admin/lithium/prices-forecast.vue?macro=true";
import { default as prices1fc1p0R_45SPF4RL976JEV_fMXi3fpbORfrytCIpr_45mdAMeta } from "/opt/buildhome/repo/src/pages/admin/natural-graphite/prices.vue?macro=true";
import { default as price_45indexOG4kXmuVO6X2iwC48LZhKPgrNL70y7UCP_zpei7X1ooMeta } from "/opt/buildhome/repo/src/pages/admin/battery-cells/price-index.vue?macro=true";
import { default as pricesj07IL0L_hsWmAmFJcx6LPTvjXiTAT0_450E_45rbtkbvwGUMeta } from "/opt/buildhome/repo/src/pages/admin/synthetic-graphite/prices.vue?macro=true";
import { default as pricing_45indexdesV5bqItplEXbVvI1WYblVnDD78k3Gp8qzmU6hPnmkMeta } from "/opt/buildhome/repo/src/pages/admin/lithium/crud/pricing-index.vue?macro=true";
import { default as stock_45marketty7EqssGDtUxKteRfIaYjPMDk_wdiwC0zuVZMWxj0nsMeta } from "/opt/buildhome/repo/src/pages/lithium/equity-data/stock-market.vue?macro=true";
import { default as supply_45raw_45materialzct1_ao2AoAicXB70OHs6Q_45QFdISD5cPpApdMBcRA6QMeta } from "/opt/buildhome/repo/src/pages/admin/lithium/supply-raw-material.vue?macro=true";
import { default as price_45indexpdJ8j86HsJ0xgjJpODAa0VokrCnPfjBBgvqldXZms3QMeta } from "/opt/buildhome/repo/src/pages/admin/natural-graphite/price-index.vue?macro=true";
import { default as daily_45price_45indicatorSIKtDjF_45_45sp_45QJpfOeuIRE22saxmAdXih2sAQr_56ZUMeta } from "/opt/buildhome/repo/src/pages/admin/lithium/daily-price-indicator.vue?macro=true";
import { default as price_45indexc1vu8R4s1VuSdTGlpq0LMc0LaYUW_BnxXQe7yRg6fGIMeta } from "/opt/buildhome/repo/src/pages/admin/synthetic-graphite/price-index.vue?macro=true";
import { default as raw_45material_45index_NRCL3fHP8ovS7_45xlpa4mugOChcnSOAV9mkGz9DZ3w0Meta } from "/opt/buildhome/repo/src/pages/admin/battery-cells/raw-material-index.vue?macro=true";
export default [
  {
    name: "home",
    path: "/home",
    component: () => import("/opt/buildhome/repo/src/pages/home.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexL6IBblXwOeGe20PFY6CEA_XHd4mNKrrU5AlHwcK4aA4Meta || {},
    component: () => import("/opt/buildhome/repo/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginbJvt5TGg7rlFXbQ_BRonmdD9Z27Cc7WHsQr4puFvsUYMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/login.vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/opt/buildhome/repo/src/pages/account.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexb6lWDIOgpbk9hb7zBD4RytNqZfgABlB1U_40CnWtGMIMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/admin/index.vue")
  },
  {
    name: "lithium-costs",
    path: "/lithium/costs",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/costs.vue"),
    children: [
  {
    name: "lithium-costs-product-type",
    path: ":product()/:type()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/costs/[product]/[type].vue")
  }
]
  },
  {
    name: "lithium-demand",
    path: "/lithium/demand",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/demand.vue")
  },
  {
    name: prices1YcWEiJyF3TjLgXwyRzy2krgQymacxXNkj0vUklSQ1MMeta?.name,
    path: "/lithium/prices",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/prices.vue"),
    children: [
  {
    name: "lithium-prices-utils",
    path: "utils",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/prices/utils.ts")
  },
  {
    name: "lithium-prices",
    path: "",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/prices/index.vue")
  },
  {
    name: "lithium-prices-range",
    path: "range",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/prices/range.vue")
  },
  {
    name: "lithium-prices-table",
    path: "table",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/prices/table.vue")
  },
  {
    name: "lithium-prices-prices",
    path: "prices",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/prices/prices.vue")
  },
  {
    name: "lithium-prices-forecasts",
    path: "forecasts",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/prices/forecasts.vue")
  },
  {
    name: "lithium-prices-priceindex",
    path: "priceindex",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/prices/priceindex.vue")
  }
]
  },
  {
    name: "request-access",
    path: "/request-access",
    meta: request_45accessD3YpmteoaZsbwC7fLRaLgD967Q2CuusJ7ge1hB1Xe4MMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/request-access.vue")
  },
  {
    name: "lithium-overview",
    path: "/lithium/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/overview.vue"),
    children: [
  {
    name: "lithium-overview-tab",
    path: ":tab()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/overview/[tab].vue")
  }
]
  },
  {
    name: "admin-anodes-prices",
    path: "/admin/anodes/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/anodes/prices.vue")
  },
  {
    name: "admin-cobalt-prices",
    path: "/admin/cobalt/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/cobalt/prices.vue")
  },
  {
    name: "admin-lithium-costs",
    path: "/admin/lithium/costs",
    component: () => import("/opt/buildhome/repo/src/pages/admin/lithium/costs.vue")
  },
  {
    name: "admin-nickel-prices",
    path: "/admin/nickel/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/nickel/prices.vue")
  },
  {
    name: "admin-lithium-prices",
    path: "/admin/lithium/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/lithium/prices.vue")
  },
  {
    name: "lithium-supply-mined",
    path: "/lithium/supply/mined",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/mined.vue"),
    children: [
  {
    name: "lithium-supply-mined-mines-id",
    path: "mines/:id()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/mined/mines/[id].vue")
  },
  {
    name: "lithium-supply-mined-regions-id",
    path: "regions/:id()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/mined/regions/[id].vue")
  },
  {
    name: "lithium-supply-mined-ore-type-id",
    path: "ore-type/:id()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/mined/ore-type/[id].vue")
  },
  {
    name: "lithium-supply-mined-companies-id",
    path: "companies/:id()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/mined/companies/[id].vue")
  },
  {
    name: "lithium-supply-mined-countries-id",
    path: "countries/:id()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/mined/countries/[id].vue")
  },
  {
    name: "lithium-supply-mined-mines-overview",
    path: "mines/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/mined/mines/overview.vue")
  },
  {
    name: "lithium-supply-mined-regions-overview",
    path: "regions/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/mined/regions/overview.vue")
  },
  {
    name: "lithium-supply-mined-ore-type-overview",
    path: "ore-type/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/mined/ore-type/overview.vue")
  },
  {
    name: "lithium-supply-mined-companies-overview",
    path: "companies/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/mined/companies/overview.vue")
  },
  {
    name: "lithium-supply-mined-countries-overview",
    path: "countries/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/mined/countries/overview.vue")
  }
]
  },
  {
    name: "admin-cathodes-prices",
    path: "/admin/cathodes/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/cathodes/prices.vue")
  },
  {
    name: "admin-lithium-ev-sales",
    path: "/admin/lithium/ev-sales",
    component: () => import("/opt/buildhome/repo/src/pages/admin/lithium/ev-sales.vue")
  },
  {
    name: "admin-lithium-forecast",
    path: "/admin/lithium/forecast",
    component: () => import("/opt/buildhome/repo/src/pages/admin/lithium/forecast.vue")
  },
  {
    name: "admin-manganese-prices",
    path: "/admin/manganese/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/manganese/prices.vue")
  },
  {
    name: "admin-users-management",
    path: "/admin/users/management",
    component: () => import("/opt/buildhome/repo/src/pages/admin/users/management.vue")
  },
  {
    name: "admin-black-mass-prices",
    path: "/admin/black-mass/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/black-mass/prices.vue")
  },
  {
    name: "lithium-supply-chemical",
    path: "/lithium/supply/chemical",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical.vue"),
    children: [
  {
    name: "lithium-supply-chemical-plants-id",
    path: "plants/:id()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/plants/[id].vue")
  },
  {
    name: "lithium-supply-chemical-regions-id",
    path: "regions/:id()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/regions/[id].vue")
  },
  {
    name: "lithium-supply-chemical-products-id",
    path: "products/:id()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/products/[id].vue")
  },
  {
    name: "lithium-supply-chemical-companies-id",
    path: "companies/:id()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/companies/[id].vue")
  },
  {
    name: "lithium-supply-chemical-countries-id",
    path: "countries/:id()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/countries/[id].vue")
  },
  {
    name: "lithium-supply-chemical-feedstocks-id",
    path: "feedstocks/:id()",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/feedstocks/[id].vue")
  },
  {
    name: "lithium-supply-chemical-plants-overview",
    path: "plants/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/plants/overview.vue")
  },
  {
    name: "lithium-supply-chemical-regions-overview",
    path: "regions/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/regions/overview.vue")
  },
  {
    name: "lithium-supply-chemical-products-overview",
    path: "products/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/products/overview.vue")
  },
  {
    name: "lithium-supply-chemical-companies-overview",
    path: "companies/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/companies/overview.vue")
  },
  {
    name: "lithium-supply-chemical-countries-overview",
    path: "countries/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/countries/overview.vue")
  },
  {
    name: "lithium-supply-chemical-feedstocks-overview",
    path: "feedstocks/overview",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/supply/chemical/feedstocks/overview.vue")
  }
]
  },
  {
    name: "admin-anodes-price-index",
    path: "/admin/anodes/price-index",
    component: () => import("/opt/buildhome/repo/src/pages/admin/anodes/price-index.vue")
  },
  {
    name: "admin-cobalt-price-index",
    path: "/admin/cobalt/price-index",
    component: () => import("/opt/buildhome/repo/src/pages/admin/cobalt/price-index.vue")
  },
  {
    name: "admin-electrolyte-prices",
    path: "/admin/electrolyte/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/electrolyte/prices.vue")
  },
  {
    name: "admin-nickel-price-index",
    path: "/admin/nickel/price-index",
    component: () => import("/opt/buildhome/repo/src/pages/admin/nickel/price-index.vue")
  },
  {
    name: "admin-rare-earths-prices",
    path: "/admin/rare-earths/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/rare-earths/prices.vue")
  },
  {
    name: "admin-anodes-denormalized",
    path: "/admin/anodes/denormalized",
    meta: denormalized7rhKWcgB5Soyh54fe2HT_rXpaBN4Rw5wU_sxedhVlRMMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/admin/anodes/denormalized.vue")
  },
  {
    name: "admin-lithium-price-index",
    path: "/admin/lithium/price-index",
    component: () => import("/opt/buildhome/repo/src/pages/admin/lithium/price-index.vue")
  },
  {
    name: "admin-battery-cells-prices",
    path: "/admin/battery-cells/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/battery-cells/prices.vue")
  },
  {
    name: "admin-cathodes-price-index",
    path: "/admin/cathodes/price-index",
    component: () => import("/opt/buildhome/repo/src/pages/admin/cathodes/price-index.vue")
  },
  {
    name: "admin-lithium-crud-pricing",
    path: "/admin/lithium/crud/pricing",
    component: () => import("/opt/buildhome/repo/src/pages/admin/lithium/crud/pricing.vue")
  },
  {
    name: "admin-price-spotlight",
    path: "/admin/price-spotlight",
    component: () => import("/opt/buildhome/repo/src/pages/admin/price-spotlight/index.vue")
  },
  {
    name: "admin-black-mass-price-index",
    path: "/admin/black-mass/price-index",
    component: () => import("/opt/buildhome/repo/src/pages/admin/black-mass/price-index.vue")
  },
  {
    name: "admin-lithium-prices-forecast",
    path: "/admin/lithium/prices-forecast",
    component: () => import("/opt/buildhome/repo/src/pages/admin/lithium/prices-forecast.vue")
  },
  {
    name: "admin-natural-graphite-prices",
    path: "/admin/natural-graphite/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/natural-graphite/prices.vue")
  },
  {
    name: "admin-battery-cells-price-index",
    path: "/admin/battery-cells/price-index",
    component: () => import("/opt/buildhome/repo/src/pages/admin/battery-cells/price-index.vue")
  },
  {
    name: "admin-synthetic-graphite-prices",
    path: "/admin/synthetic-graphite/prices",
    component: () => import("/opt/buildhome/repo/src/pages/admin/synthetic-graphite/prices.vue")
  },
  {
    name: "admin-lithium-crud-pricing-index",
    path: "/admin/lithium/crud/pricing-index",
    component: () => import("/opt/buildhome/repo/src/pages/admin/lithium/crud/pricing-index.vue")
  },
  {
    name: "lithium-equity-data-stock-market",
    path: "/lithium/equity-data/stock-market",
    component: () => import("/opt/buildhome/repo/src/pages/lithium/equity-data/stock-market.vue")
  },
  {
    name: "admin-lithium-supply-raw-material",
    path: "/admin/lithium/supply-raw-material",
    component: () => import("/opt/buildhome/repo/src/pages/admin/lithium/supply-raw-material.vue")
  },
  {
    name: "admin-natural-graphite-price-index",
    path: "/admin/natural-graphite/price-index",
    component: () => import("/opt/buildhome/repo/src/pages/admin/natural-graphite/price-index.vue")
  },
  {
    name: "admin-lithium-daily-price-indicator",
    path: "/admin/lithium/daily-price-indicator",
    component: () => import("/opt/buildhome/repo/src/pages/admin/lithium/daily-price-indicator.vue")
  },
  {
    name: "admin-synthetic-graphite-price-index",
    path: "/admin/synthetic-graphite/price-index",
    component: () => import("/opt/buildhome/repo/src/pages/admin/synthetic-graphite/price-index.vue")
  },
  {
    name: "admin-battery-cells-raw-material-index",
    path: "/admin/battery-cells/raw-material-index",
    component: () => import("/opt/buildhome/repo/src/pages/admin/battery-cells/raw-material-index.vue")
  }
]