import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_7DB4Q_rSg7Y6_r2xTJAR9sj0Plzl7GeeI8C3uqDzeqY from "/opt/buildhome/repo/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import amcharts_DekiyMAF_vJLxhQ75ioGQeb8UAxGvO0Ac3Jv8_y__Jo from "/opt/buildhome/repo/src/plugins/amcharts.js";
import auth0_client_rMc9IOX_QME5uEUtdLw7WeGbRM5xuGuEXI3krQjdrrs from "/opt/buildhome/repo/src/plugins/auth0.client.ts";
import authorization_client_KpZHec9sYyA5fxtWNBBfJrTziOxSh7N9JJw83PrgKXc from "/opt/buildhome/repo/src/plugins/authorization.client.ts";
import axios_client_mNX19oPJu_yAwPxCAIqsmiEiLjgPVaRSuIHogyEqKJk from "/opt/buildhome/repo/src/plugins/axios.client.ts";
import chartjs_eFOXX9Jmhvj581_nzVmATsXDX5rXxSTMIDZ3iWmSq6Y from "/opt/buildhome/repo/src/plugins/chartjs.js";
import dateRangePicker_client_hzRZTnDkmFYVkI03CwgrEnnhfjZdz4TAudhGcVC9imA from "/opt/buildhome/repo/src/plugins/dateRangePicker.client.js";
import directives_UCGUdoHEDTmL_q_0fieSXbbdilCphKPl7hmznx8dTa0 from "/opt/buildhome/repo/src/plugins/directives.js";
import error_handler_rLaONCpBi_o3fpyg_8YksOhfcjAcQ26_0of0GaTmX3o from "/opt/buildhome/repo/src/plugins/error-handler.ts";
import sentry_client_config_ZzvrBkkZ_ga46ijKXHkMyFkXL6vmag2e0y9LRshcTjA from "/opt/buildhome/repo/src/plugins/sentry.client.config.ts";
import toast_service_client_bJo85qhk6wHIjPjOm_jWKYNLKjvTiulAlqNwctSo6P0 from "/opt/buildhome/repo/src/plugins/toast-service.client.js";
import toastErrors_65EuI5Z6h56iSDGNQDBxftTvF97mv1IamyRRlaQ5jpg from "/opt/buildhome/repo/src/plugins/toastErrors.js";
import vee_validate_client_KvWx30dbtRTyIVMf4x03BEcShfBMbE4GIPuejdCzKR8 from "/opt/buildhome/repo/src/plugins/vee-validate.client.js";
import vueDOMPurifyHTML_client_ARRIVdQIPxdUR_CRzi_eLKWQJyoVLFT4wcJht_mRGTU from "/opt/buildhome/repo/src/plugins/vueDOMPurifyHTML.client.js";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_7DB4Q_rSg7Y6_r2xTJAR9sj0Plzl7GeeI8C3uqDzeqY,
  amcharts_DekiyMAF_vJLxhQ75ioGQeb8UAxGvO0Ac3Jv8_y__Jo,
  auth0_client_rMc9IOX_QME5uEUtdLw7WeGbRM5xuGuEXI3krQjdrrs,
  authorization_client_KpZHec9sYyA5fxtWNBBfJrTziOxSh7N9JJw83PrgKXc,
  axios_client_mNX19oPJu_yAwPxCAIqsmiEiLjgPVaRSuIHogyEqKJk,
  chartjs_eFOXX9Jmhvj581_nzVmATsXDX5rXxSTMIDZ3iWmSq6Y,
  dateRangePicker_client_hzRZTnDkmFYVkI03CwgrEnnhfjZdz4TAudhGcVC9imA,
  directives_UCGUdoHEDTmL_q_0fieSXbbdilCphKPl7hmznx8dTa0,
  error_handler_rLaONCpBi_o3fpyg_8YksOhfcjAcQ26_0of0GaTmX3o,
  sentry_client_config_ZzvrBkkZ_ga46ijKXHkMyFkXL6vmag2e0y9LRshcTjA,
  toast_service_client_bJo85qhk6wHIjPjOm_jWKYNLKjvTiulAlqNwctSo6P0,
  toastErrors_65EuI5Z6h56iSDGNQDBxftTvF97mv1IamyRRlaQ5jpg,
  vee_validate_client_KvWx30dbtRTyIVMf4x03BEcShfBMbE4GIPuejdCzKR8,
  vueDOMPurifyHTML_client_ARRIVdQIPxdUR_CRzi_eLKWQJyoVLFT4wcJht_mRGTU
]